import useCookieGeoLocation from '../../../../../../modules/use-cookie-geo-location'
import useSelectLocation from '../../../../../../modules/use-select-location'
import { useUserContext } from '../../../../../../components/ContextUser'
import { useEffect, useState } from 'react'
import isServerSide from '../../../../../../modules/is-server-side'

const useLocationData = () => {
  if (isServerSide()) {
    return {}
  }
  let regex = /location\("([^"]+)"\)/
  let appMatch = navigator.userAgent.match(regex)
  let appLocation = null
  if (appMatch) {
    appLocation = appMatch[1]
  }

  const [data, setData] = useState({})
  let profileCommune = null
  const cookieGeoLocation = useCookieGeoLocation()
  const selectLocation = useSelectLocation()
  const { profile } = useUserContext()
  if (profile?.user_municipality && JSON.parse(profile?.user_municipality)[0] && JSON.parse(profile?.user_municipality)[0].length>0) {
    profileCommune = profile?.user_municipality && JSON.parse(profile?.user_municipality)[0] && JSON.parse(profile?.user_municipality)[0].length>0 && JSON.parse(profile?.user_municipality)[0].trim()
  }

  const location = appLocation || cookieGeoLocation.cookie || profileCommune

  useEffect(() => {
    if (!selectLocation.loading && location) {
      const selectedLocation = selectLocation.setLocation(location)

      if (!selectedLocation?.city_name && data.label) {
        return data
      }
      setData({
        label: selectedLocation.city_name,
      })
    }
  }, [selectLocation.loading, location])

  return data
}

export default useLocationData
