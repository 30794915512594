import { createSessionIDAndHashedMail } from '../../../../../../bundles/BundleAds/modules/hashed-emails'
import isServerSide from '../../../../../../modules/is-server-side'
import options from '../../../../../../config/options'

const getDefaultOptions = () => {
  if (isServerSide()) {
    return {}
  }
  const slcData = {}
  slcData.siteId = options?.SLC?.siteId

  let regex = /adId\("([^"]+)"\)/
  let appMatch = navigator?.userAgent?.match(regex)
  let uaAdId = null
  if (appMatch) {
    uaAdId = appMatch[1]
  }
  const slcPrefix = options?.SLC?.prefix
  const ids = slcPrefix && createSessionIDAndHashedMail({ prefix: slcPrefix })
  const adId = window?.device_native_advertising_identifier
  if (adId || uaAdId || ids?.sessionId) {
    slcData.ui = uaAdId || adId || ids?.sessionId
  }

  const userEmail = !isServerSide() && dlab?.user?.email
  if (userEmail) {
    const uie = userEmail.md5?.toString()
    slcData.uie = uie
  }

  return slcData
}

export default getDefaultOptions
