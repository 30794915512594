import React, {useEffect} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import translate from '../../../../modules/translate'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import MessageError from '../../../Error/screens/MessageError'
import isServerSide from '../../../../modules/is-server-side'
import {useState} from "react"
import GeoLocationWallWidget from "../../components/GeoLocationWallWidget";
import Modal from "@mui/material/Modal";
import useCookieGeoLocation from "../../../../modules/use-cookie-geo-location";
import useCookieGeoCoordinates from "../../../../modules/use-cookie-geo-coordinates";
import {clientCoordinates} from "../../../../modules/location";
import Config from "../../../../config";
import routes from "../../../routes";

const StyledModal = styled(Modal)`
outline: none;
z-index:2001 !important;
`
const StyledBox = styled.div`
outline: none;
  
`
const ScreenGeolocationDialog = ({showModal=false, hideGPS=false, ...props} ) => {
  const geoCookie = useCookieGeoLocation()
  const geoCookieCoordinates=useCookieGeoCoordinates()
  const [open,setOpen]=useState(showModal)

  const [gpsError,setGpsError]=useState(false)
  const [selectedLocation,setSelectedLocation] = useState("")
  const parseSelectedLocation=(paramSelectedLocation)=>{
    setSelectedLocation(paramSelectedLocation.selectedLocation.location)
  }
  const handleClose=()=>{
        setOpen(false)
      if(window && window.athesia_react && window.athesia_react.toggleGeoLocationDialog){
        window.athesia_react.toggleGeoLocationDialog(false)
      }
  }

  return (
    <StyledModal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

    >
      <StyledBox>
        <GeoLocationWallWidget
          hideGPS={hideGPS}
          gpsError={gpsError}
          gpsLocationCallback={()=>{
            let latitude = undefined
            let longitude = undefined
            let accuracy = undefined
            return new Promise((resolve) => {
              clientCoordinates()
                .then((coordinates) => {
                  latitude = parseFloat(coordinates.latitude).toFixed(6)
                  longitude = parseFloat(coordinates.longitude).toFixed(6)
                  accuracy = parseFloat(coordinates.accuracy).toFixed(6)
                  if (latitude !== undefined && longitude !== undefined && accuracy !== undefined) {
                    geoCookieCoordinates.save({latitude:latitude,longitude:longitude})
                    handleClose()
                  } else {
                    geoCookieCoordinates.remove()
                    setGpsError(true)
                  }
                })
                .catch(() => {
                  geoCookieCoordinates.remove()
                  setGpsError(true)
                })
            })

          }}
          geoLocationCallback={parseSelectedLocation}
          registerCallback={()=>{
          if(!isServerSide()){

            tp.push(["init", function() {
              tp.pianoId.show({
                disableSignUp: false,
                displayMode: 'modal',
                screen: 'register',
                showCloseButton: false,
                loginSuccess: function(data) {
                  tp.experience.execute();

                },
                loggedOut: function() {
                }
              });
            }]);
            handleClose();

          }}}
          loginCallback={()=>{
          if(!isServerSide()){

            tp.push(["init", function() {
              tp.pianoId.show({
                disableSignUp: false,
                displayMode: 'modal',
                screen: 'login',
                showCloseButton: false,

                loginSuccess: function(data) {
                  console.log("LOGIN")
                  tp.experience.execute();

                },
                loggedIn: function(data) {
                  console.log("LOGIN")
                  tp.experience.execute();

                },
                loggedOut: function() {
                }
              });
            }]);
            handleClose();
          }}}
          sendGeoLocationCallback={selectedLocation && selectedLocation.length>0 ? ()=>{geoCookie.save(selectedLocation); handleClose()} : ""}

        ></GeoLocationWallWidget>
      </StyledBox>





    </StyledModal>
   )
}


export default ScreenGeolocationDialog
