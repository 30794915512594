import getDefaultOptions from './modules/get-default-options'
import useCoordinatesData from './modules/use-coordinates-data'
import useLocationData from './modules/use-location-data'
import { useEffect } from 'react'

import { clearStoredItem, getStoredItem, setStoredItem } from '../../../../modules/stored-item'
import isStolApp from '../../../../modules/is-stolapp'
import appSendGpsWallData from '../../../../modules/app-send-gps-wall-data'

const SLCEvent = () => {
  const geoCoordinates = useCoordinatesData()
  const geoLocation = useLocationData()

  let prevSlcData = getStoredItem('SLCLocation', true)
  prevSlcData = prevSlcData ? JSON.parse(prevSlcData) : null
  let slcData = {
    ...getDefaultOptions(),
    ...geoLocation,
  }
  if (!geoCoordinates.loading && geoCoordinates.data) {
    slcData = {
      ...slcData,
      ...geoCoordinates.data,
    }
  }

  useEffect(() => {
    const requiredSLCData = slcData?.label
    const isEqualPrevData = prevSlcData && slcData.label === prevSlcData.label

    if (!geoCoordinates.loading && !window?.slcAdsTargeting?.info && requiredSLCData && isStolApp()) {
      appSendGpsWallData({ location: geoCoordinates.selectedLocation })
    }

    if (requiredSLCData && !isEqualPrevData) {
      const sendEvent = () => {
        if (window.SLC) {
          window.SLC && window.SLC('pageEvent', 'geo-city', 'set', JSON.stringify(slcData.label))
          setStoredItem('SLCLocation', JSON.stringify(slcData), true)
        }
      }

      if (!window.SLC) {
        window.SLCQueue = window.SLCQueue || []
        window.SLCQueue.push(sendEvent)
      }
      sendEvent()
    }
    return () => {
      if (!requiredSLCData && !isEqualPrevData) {
        clearStoredItem('SLCLocation', true)
      }
    }
  }, [slcData.label, slcData.gs])

  return true
}

export default SLCEvent
