import isStolApp from '../is-stolapp'
import isServerSide from '../is-server-side'

const getAppCoordinates = () => {
  if (!isStolApp() || isServerSide() || !window?.navigator?.userAgent) {
    return false
  }
  const hasGps = window?.navigator?.userAgent?.includes('gps(')
  if (!hasGps) {
    return false
  }
  let coordinatesArray = window.navigator.userAgent.split('gps(')[1].split(')')[0].split(',')

  if (
    coordinatesArray &&
    coordinatesArray.length > 0 &&
    coordinatesArray[0] !== 'null' &&
    coordinatesArray[0] !== undefined &&
    coordinatesArray[0] !== null &&
    coordinatesArray[0] !== 'undefined'
  ) {
    let coordinates = {
      latitude: parseFloat(coordinatesArray[0]),
      longitude: parseFloat(coordinatesArray[1]),
      accuracy: parseFloat(coordinatesArray[2]),
    }
    return coordinates
  }

  return false
}

export default getAppCoordinates
