import React, {Fragment, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isStolApp from "../../modules/is-stolapp";
import isServerSide from "../../modules/is-server-side";
import ScreenGeolocationDialog from "../../topics/GeolocationWall/screens/ScreenGeolocation";
import useCookieGeoCoordinates from "../../modules/use-cookie-geo-coordinates";
import useCookieGeoLocation from "../../modules/use-cookie-geo-location";

import {AdsProvider} from "../ContextAds";
import {clientCoordinates} from "../../modules/location";



const Wrapper = styled.div``
const GeoWallToggler = ({...props}) => {

  const [showGeoLocationDialog,setShowGeoLocationDialog]=useState(false)
  const [hideGps,setHideGps]=useState(false)
  const geoLocationCookie=useCookieGeoLocation()
  const geoCoordinatesCookie=useCookieGeoCoordinates()

  const toggleGeoLocationDialog=(show)=>{
    if(show!==undefined && setShowGeoLocationDialog){
      setShowGeoLocationDialog(show)
    }
  }


  const geoCookieChecker=()=>{

    if(geoCoordinatesCookie && geoCoordinatesCookie.cookie){
        let latitude = undefined
        let longitude = undefined
        let accuracy = undefined
        return new Promise((resolve) => {
          clientCoordinates()
            .then((coordinates) => {
              latitude = parseFloat(coordinates.latitude).toFixed(6)
              longitude = parseFloat(coordinates.longitude).toFixed(6)
              accuracy = parseFloat(coordinates.accuracy).toFixed(6)
              if (latitude !== undefined && longitude !== undefined && accuracy !== undefined) {
                geoCoordinatesCookie.save({latitude:latitude,longitude:longitude})
              } else {

                geoCoordinatesCookie.remove()
                setHideGps(true)
                toggleGeoLocationDialog(true)
              }
            })
            .catch(() => {
              geoCoordinatesCookie.remove()
              setHideGps(true)
              toggleGeoLocationDialog(true)
            })
        })



    }
    if(geoLocationCookie.cookie && (!geoCoordinatesCookie || !geoCoordinatesCookie.cookie)){
      console.log("HABE GEMEINDE")
      return
    }

    if(!geoLocationCookie.cookie && !geoCoordinatesCookie.cookie){
      console.log("PASST")
        setShowGeoLocationDialog(true)
      return
    }
    console.log("NIX KLAPPT")

  }


  if(!isStolApp()){
    if (!isServerSide() && window && window.athesia_react ) {
      if(!window.athesia_react.geoCookieChecker){
        window.athesia_react = { geoCookieChecker, ...window.athesia_react }
      }else{
        window.athesia_react.geoCookieChecker=geoCookieChecker
      }

      if(!window.athesia_react.toggleGeoLocationDialog){
        window.athesia_react = { toggleGeoLocationDialog, ...window.athesia_react }
      }else{
        window.athesia_react.toggleGeoLocationDialog=toggleGeoLocationDialog
      }


    }
  }




  return ( <React.Fragment>{
    showGeoLocationDialog && !isStolApp() &&
  <ScreenGeolocationDialog showModal={showGeoLocationDialog} hideGPS={hideGps}
    />}</React.Fragment>)
}
GeoWallToggler.propTypes = {}
GeoWallToggler.defaultProps = {}
export default GeoWallToggler
