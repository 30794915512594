import React from 'react'

function GeoWallUserIcon(props) {
  return (

    <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.9024 31.4686C32.6417 27.5603 29.1579 24.7578 25.0922 23.4293C27.1033 22.2321 28.6658 20.4078 29.5397 18.2366C30.4136 16.0654 30.5507 13.6674 29.9299 11.4108C29.309 9.15419 27.9646 7.16377 26.103 5.7452C24.2415 4.32663 21.9657 3.55835 19.6252 3.55835C17.2848 3.55835 15.009 4.32663 13.1475 5.7452C11.2859 7.16377 9.94148 9.15419 9.32063 11.4108C8.69978 13.6674 8.83685 16.0654 9.71079 18.2366C10.5847 20.4078 12.1472 22.2321 14.1583 23.4293C10.0926 24.7563 6.60876 27.5588 4.34806 31.4686C4.26516 31.6038 4.21017 31.7542 4.18634 31.911C4.16251 32.0678 4.17032 32.2277 4.20931 32.3815C4.2483 32.5352 4.31768 32.6795 4.41336 32.806C4.50904 32.9324 4.62908 33.0384 4.7664 33.1178C4.90371 33.1971 5.05552 33.2481 5.21287 33.2678C5.37022 33.2875 5.52992 33.2754 5.68255 33.2324C5.83517 33.1894 5.97764 33.1162 6.10154 33.0173C6.22543 32.9183 6.32825 32.7955 6.40392 32.6561C9.20048 27.823 14.1435 24.9374 19.6252 24.9374C25.107 24.9374 30.05 27.823 32.8466 32.6561C32.9223 32.7955 33.0251 32.9183 33.149 33.0173C33.2729 33.1162 33.4153 33.1894 33.5679 33.2324C33.7206 33.2754 33.8803 33.2875 34.0376 33.2678C34.195 33.2481 34.3468 33.1971 34.4841 33.1178C34.6214 33.0384 34.7415 32.9324 34.8371 32.806C34.9328 32.6795 35.0022 32.5352 35.0412 32.3815C35.0802 32.2277 35.088 32.0678 35.0642 31.911C35.0403 31.7542 34.9853 31.6038 34.9024 31.4686ZM11.3127 14.2499C11.3127 12.6058 11.8003 10.9987 12.7137 9.63171C13.627 8.26473 14.9253 7.19929 16.4442 6.57014C17.9631 5.94099 19.6345 5.77637 21.2469 6.09711C22.8594 6.41785 24.3405 7.20954 25.5031 8.37206C26.6656 9.53459 27.4573 11.0157 27.778 12.6282C28.0988 14.2407 27.9342 15.912 27.305 17.4309C26.6758 18.9499 25.6104 20.2481 24.2434 21.1615C22.8764 22.0749 21.2693 22.5624 19.6252 22.5624C17.4214 22.56 15.3084 21.6835 13.75 20.1251C12.1916 18.5667 11.3151 16.4538 11.3127 14.2499Z" fill="black"/>
    </svg>

  )
}

export default GeoWallUserIcon
