import React from 'react'

function GeoWallMap(props) {
  return (


    <svg width="58" height="37" viewBox="0 0 58 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.2695 24.2865L23.5897 19.6461L23.203 16.1658L25.5233 13.8456L21.2695 11.9121V8.04507M25.1366 14.2323L28.2302 16.5525L28.6169 21.5796L27.4568 25.06L31.3238 21.5796L34.0307 25.06M37.1243 25.06L36.3509 20.8062L37.1243 15.3924L33.644 13.0722V9.20517L36.7376 7.65836L36.3509 5.72485" stroke="#BCBCBC"/>
      <path d="M26.6828 6.88494C25.136 6.57558 22.9446 7.01384 22.0423 7.27164C20.1169 7.65835 18.562 13.8456 17.7893 14.619C17.0165 15.3924 10.828 15.3924 10.828 13.8456C10.828 12.2988 9.66852 11.5254 8.50841 11.1387C7.34831 10.752 7.34831 12.6855 4.25405 11.5254C1.15979 10.3653 4.25405 14.2323 1.93412 17.7126C-0.385809 21.1929 5.41415 18.8727 4.25405 21.9663C3.09394 25.0599 5.02745 25.8333 6.18756 25.8333C7.73437 28.5403 9.66788 26.6068 10.828 25.4466C12.9935 27.3028 15.5973 26.22 16.6285 25.4466C17.0152 25.8333 17.9433 26.6068 18.562 26.6068C19.3354 26.6068 20.8822 23.1264 21.2689 25.0599C21.6556 26.9935 19.3354 30.4738 19.3354 31.6339C19.3354 32.794 21.2689 33.1807 22.429 34.3408C23.3571 32.4846 25.3938 32.5362 26.2961 32.794C27.0695 32.1495 28.8483 30.6285 29.7764 29.7004C31.6326 30.6285 33.6434 26.7357 34.4168 24.6732C35.5769 25.0599 37.8971 25.6787 37.8971 25.0599C37.8971 24.2865 41.7641 23.1264 42.9242 23.1264C43.8523 23.1264 44.8578 19.775 45.2445 18.0993C46.2757 19.0016 48.3381 20.6515 48.3381 20.0328C48.3381 19.2594 52.5918 20.0328 52.5918 18.8727C52.5918 17.7126 58.3923 17.7126 56.0721 16.9392C53.7519 16.1658 50.2716 13.8456 50.6583 12.2988C51.045 10.752 49.8849 9.97856 49.1115 8.81846C44.4711 8.81846 46.4046 6.11154 46.4046 5.33814C46.4046 4.56474 48.3381 3.01792 47.5647 1.85781C46.7913 0.697705 39.0572 4.95143 35.5769 6.11154C31.3232 4.95143 28.6163 7.27164 26.6828 6.88494Z" stroke="black" stroke-width="2.2"/>
    </svg>

  )
}

export default GeoWallMap
