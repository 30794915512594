import React from 'react'

function GeoWallPin({inActive=false,props}) {
  return (

    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.625 11.875C16.625 11.4053 16.7643 10.9461 17.0253 10.5555C17.2862 10.165 17.6572 9.86054 18.0911 9.68079C18.5251 9.50103 19.0026 9.45399 19.4633 9.54563C19.924 9.63727 20.3472 9.86347 20.6794 10.1956C21.0115 10.5278 21.2377 10.951 21.3294 11.4117C21.421 11.8724 21.374 12.3499 21.1942 12.7839C21.0145 13.2178 20.71 13.5888 20.3195 13.8497C19.9289 14.1107 19.4697 14.25 19 14.25C18.3701 14.25 17.766 13.9998 17.3206 13.5544C16.8752 13.109 16.625 12.5049 16.625 11.875ZM9.5 11.875C9.5 9.35544 10.5009 6.93908 12.2825 5.15749C14.0641 3.37589 16.4804 2.375 19 2.375C21.5196 2.375 23.9359 3.37589 25.7175 5.15749C27.4991 6.93908 28.5 9.35544 28.5 11.875C28.5 20.7738 19.953 25.7598 19.5938 25.9691C19.4143 26.0717 19.2112 26.1257 19.0045 26.1257C18.7977 26.1257 18.5946 26.0717 18.4152 25.9691C18.047 25.7598 9.5 20.7812 9.5 11.875ZM11.875 11.875C11.875 18.1391 17.195 22.2968 19 23.5273C20.8035 22.2983 26.125 18.1391 26.125 11.875C26.125 9.98533 25.3743 8.17306 24.0381 6.83686C22.7019 5.50067 20.8897 4.75 19 4.75C17.1103 4.75 15.2981 5.50067 13.9619 6.83686C12.6257 8.17306 11.875 9.98533 11.875 11.875ZM30.0987 21.9138C29.8064 21.8173 29.4881 21.838 29.2108 21.9716C28.9334 22.1052 28.7189 22.3412 28.6122 22.63C28.5056 22.9187 28.5152 23.2376 28.6391 23.5194C28.763 23.8011 28.9915 24.0238 29.2763 24.1404C31.727 25.0473 33.25 26.2616 33.25 27.3125C33.25 29.2956 27.8291 32.0625 19 32.0625C10.1709 32.0625 4.75 29.2956 4.75 27.3125C4.75 26.2616 6.27297 25.0473 8.72367 24.1419C9.00854 24.0253 9.237 23.8026 9.3609 23.5208C9.48479 23.2391 9.49443 22.9202 9.38779 22.6315C9.28115 22.3427 9.06655 22.1067 8.78925 21.9731C8.51194 21.8395 8.1936 21.8188 7.90133 21.9153C4.33734 23.229 2.375 25.1468 2.375 27.3125C2.375 31.9408 10.9413 34.4375 19 34.4375C27.0587 34.4375 35.625 31.9408 35.625 27.3125C35.625 25.1468 33.6627 23.229 30.0987 21.9138Z" fill={inActive? "#D3D3D3": "black"}/>
    </svg>

  )
}

export default GeoWallPin
