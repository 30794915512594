import handlerWindowDlab from '../../bundles/BundleAds/modules/handler-window-dlab'
import isStolApp from '../is-stolapp'
import hasItems from '../has-items'
import isAppVersionReady from '../is-app-version-ready'

const appSendGpsWallData = ({ location = null, targets = null }) => {
  const { dlabOption: slcOption } = handlerWindowDlab('ads', { sub: 'SLC' })
  const { dlabOption: userOption } = handlerWindowDlab('user')
  const dlabLocation = userOption?.dlabLocation || location
  const slcTargets = slcOption?.targets || targets

  if (!isStolApp()) {
    return
  }
  if (!userOption?.dlabLocation && location !== null) {
    userOption.dlabLocation = location
  }
  if (!slcOption?.targets && targets !== null) {
    slcOption.targets = targets
  }
  if (dlabLocation && !userOption.appSendLocation) {
    window.slcAdsTargeting = {
      ...window.slcAdsTargeting,
      info: dlabLocation,
    }
    const dlabLocationString = JSON.stringify(dlabLocation)
    if (isAppVersionReady('4.13', '4.8.7')) {
      window.location.href = `stolapp://gps-wall/update-location?data=${encodeURIComponent(dlabLocationString)}`
    }

    userOption.appSendLocation = true
  }
  if (slcTargets && !slcOption.appSendSLCTargets) {
    window.slcAdsTargeting = {
      ...window.slcAdsTargeting,
      slc: slcTargets,
    }
    const slcTargetsString = JSON.stringify(slcTargets)
    if (isAppVersionReady('4.13', '4.8.7')) {
      window.location.href = `stolapp://gps-wall/update-slc-targeting?data=${encodeURIComponent(slcTargetsString)}`
    }
    slcOption.appSendSLCTargets = true
  }
}

export default appSendGpsWallData
