import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SelectLocation from "../../../../components/SelectLocation";
import translate from "../../../../modules/translate";
import GeoWallMap from "../../../../images/GeoWallMap";
import GeoWallPin from "../../../../images/GeoWallPin";
import GeoWallUserIcon from "../../../../images/GeoWallUserIcon";
import GeoWallPlane from "../../../../images/GeoWallPlane";

const Wrapper = styled.div`
background-color: #eee;
font-family: ${(props) => props.theme.font.primary};

    padding-top: 20px;
    padding-bottom: 65px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-bottom:40px;
  }
   @media (min-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
     margin-top:15px;
    max-width:500px;
    max-height:500px;
    margin-left:auto;
    margin-right:auto;
    @media (min-height: ${(props) => '850px'}) {
      padding-bottom: 80px;
      margin-top:50px;
      max-width:650px;
      max-height:700px;
      margin-left:auto;
      margin-right:auto;
    }
  }
    

  
`
const ButtonContent = styled.div`
margin-left: auto;
    margin-right: auto;
    display: flex;
    text-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
  text-align:left;
`
const Content = styled.div`

margin:auto;
height:400px;
width:90%;
max_width:400px;
   @media (min-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
      max_width:700px;
    @media (min-height: ${(props) => '850px'}) {
      height:500px;
    }
  }
display:flex;
 flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
  height:500px;
  max_width:unset;
    margin: unset;
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
  }
`

const GeoWallButton =styled.div`
font-size:18px;
@media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
      font-size:17px;
    }
    display: flex;
    text-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
font-family: ${(props) => props.theme.font.primary};
margin-top:20px;
margin-bottom:20px;
padding:25px;
  @media (min-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
      
    @media (max-height: ${(props) => '850px'}) {
      margin-top:15px;
      margin-bottom:10px;
       padding:17px;
    }
  }
width:78%;
margin-left:auto;
margin-right:auto;
background-color: #fff;
text-align: center;
cursor: pointer;
border-radius: 10px;
font-weight:bold;
&:active{
  background-color: #ddd;
}
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
   margin-top:15px;
margin-bottom:15px;
padding:20px;
padding-left:12px;
padding-right:12px;
width:83%
  }
`
const AcceptCenterer=styled.div`
margin-left:auto;
margin-right:auto;
`

const GeoWallButtonSelection =styled(GeoWallButton)`
padding-top:25px;
padding-bottom:25px;
  @media (min-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
      
    @media (max-height: ${(props) => '850px'}) {
       padding-top:17px;
      padding-bottom:17px;
    }
  }
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      padding-top:20px;
      padding-bottom:20px;
  }
`
const IconPlace=styled.div`
margin-right:20px;

`
const StyledSelection=styled(SelectLocation)`
  width:250px;
    @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width:225px;
  }
    @media (max-width: ${(props) => '365px'}) {
    width:190px;
  }
`

const GeoWallButtonRed =styled(GeoWallButton)`
font-family: ${(props) => props.theme.font.primary};
padding:15px;
margin-top:0px;
background-color: ${(props) => props.theme.color.stol};
color:white;
padding-left: 25px;
padding-right: 25px;
  @media (min-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
      
    @media (max-height: ${(props) => '850px'}) {
       padding-left:17px;
      padding-right:17px;
    }
  }
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      padding-left: 12px;
      padding-right: 12px;
  }
&:active{
  background-color: ${(props) => props.theme.color.stolDark};
}
`
const GeoWallButtonInActive = styled(GeoWallButton)`
  cursor: unset;
  background-color: #F5F5F5;
  color:#D3D3D3;
  &:active{
    background-color: #F5F5F5;
  }
`
const GeoWallButtonRedInActive = styled(GeoWallButtonRed)`
cursor: unset;
  background-color: #F5F5F5;
  color:#D3D3D3;
  &:active{
    background-color: #F5F5F5;
  }
`
const Title = styled.div`
text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding-top: 20px;
  margin-bottom:40px;
      width: 85%;
    margin-left: auto;
    margin-right: auto;
    @media (min-height: ${(props) => '850px'}) {
      width:60%;
      font-size: 26px;
      margin-bottom:70px;
    }
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      width: 80%;
      font-size: 20px;
        margin-bottom:30px;
  }
  
`

const GeoLocationWallWidget = ( {gpsLocationCallback,geoLocationCallback,registerCallback,loginCallback,sendGeoLocationCallback,hideGPS=false,showOnlyCommune=false,gpsError=false,...props}) => (
    <Wrapper {...props}>
      { !showOnlyCommune && <Title>{translate("geo_wall_widget_title")}</Title>}
      <Content>

        {!hideGPS && !showOnlyCommune && !gpsError && <GeoWallButton onClick={()=>{gpsLocationCallback && gpsLocationCallback()}}> <ButtonContent><IconPlace> <GeoWallPin/> </IconPlace>{translate("geo_wall_widget_gps")}</ButtonContent>  </GeoWallButton>}
        {!hideGPS && !showOnlyCommune && gpsError && <GeoWallButtonInActive onClick={()=>{}}><ButtonContent><IconPlace > <GeoWallPin inActive={true}/> </IconPlace>{translate("geo_wall_widget_gps_error")}</ButtonContent> </GeoWallButtonInActive>}


        <GeoWallButtonSelection > <ButtonContent><IconPlace>  <GeoWallMap/> </IconPlace> <StyledSelection onChange={geoLocationCallback}> </StyledSelection> </ButtonContent>

        </GeoWallButtonSelection>
        {sendGeoLocationCallback && <GeoWallButtonRed  onClick={()=>{sendGeoLocationCallback && sendGeoLocationCallback()}}><AcceptCenterer> {translate("geo_wall_widget_accept")} </AcceptCenterer></GeoWallButtonRed>}
        {!sendGeoLocationCallback && <GeoWallButtonRedInActive  onClick={()=>{}}> <AcceptCenterer> {translate("geo_wall_widget_accept")} </AcceptCenterer></GeoWallButtonRedInActive>}
        { !showOnlyCommune && <GeoWallButton  onClick={()=>{registerCallback && registerCallback()}}>
          <ButtonContent><IconPlace> <GeoWallPlane/></IconPlace>
          {translate("geo_wall_widget_register1")} <br/> {translate("geo_wall_widget_register2")} </ButtonContent> </GeoWallButton>}
        { !showOnlyCommune &&<GeoWallButton  onClick={()=>{loginCallback && loginCallback()}}><ButtonContent><IconPlace> <GeoWallUserIcon/> </IconPlace> {translate("geo_wall_widget_login1")}  <br/>  {translate("geo_wall_widget_login2")} </ButtonContent>  </GeoWallButton>}

      </Content>
    </Wrapper>
)
GeoLocationWallWidget.propTypes = {}
GeoLocationWallWidget.defaultProps = {}
export default GeoLocationWallWidget
