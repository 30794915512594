import useCookieGeoCoordinates from '../../../../../../modules/use-cookie-geo-coordinates'
import { useEffect, useState } from 'react'
import useSelectLocation from '../../../../../../modules/use-select-location'
import getAppCoordinates from '../../../../../../modules/get-app-coordinates'

const useCoordinatesData = () => {
  const slcData = {}
  const cookieGeoCoordinates = useCookieGeoCoordinates()
  const selectLocation = useSelectLocation()
  const appCoordinates = getAppCoordinates()
  const coordinates = appCoordinates || cookieGeoCoordinates.cookie

  useEffect(() => {
    if (!selectLocation.loading && coordinates) {
      selectLocation.setCoordinates(coordinates)
    }
  }, [selectLocation.loading])

  if (coordinates && coordinates?.latitude && coordinates?.longitude) {
    const latitude = parseFloat(coordinates.latitude) * 1000000
    const longitude = parseFloat(coordinates.longitude) * 1000000
    slcData.gl = `${latitude},${longitude}`
  }

  if (coordinates && selectLocation.selectedLocation && coordinates?.latitude && coordinates?.longitude) {
    const geoLocation = selectLocation.selectedLocation
    slcData.label = geoLocation.commune
  }

  return {
    data: slcData,
    selectedLocation: selectLocation?.selectedLocation,
    loading: selectLocation.loading,
  }
}

export default useCoordinatesData
